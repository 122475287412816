import React, { useState } from "react";
//import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Button from '@mui/material/Button';
import PersonIcon from '@mui/icons-material/Person';
import HttpsIcon from '@mui/icons-material/Https';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { purple } from '@mui/material/colors';
import { styled, alpha } from '@mui/material/styles';
import { auth } from '../../services/user_service.js'
import {useAuth } from '../../hooks/useAuth.js'
import { useNavigate } from 'react-router-dom';
  
import './auth.css';
//import logo from '../assets/logo_nabu_login.png';
import logo_aguafly from '../../assets/logo_AQUAFLY_vector.png';


const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: purple[500],
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
});

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.35),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.45),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  marginBottom: 5,
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '30ch',
    },
  },
}));

function Auth() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { authData, setAuth } = useAuth();

  const [logout, setLogout] = useState("LOGOUT")
  const [continueTo, setContinueTo] = useState("Continue to Home Page")

  const navigate = useNavigate();

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const data = await auth({ username, password });
      setAuth(data);
      window.location.href = 'home';
    } catch (error) {
      alert(error.message);
    }
  };

  const logOut = () => {
    setAuth(null);
  }
  const continueToFunc = () => {
    window.location.href = 'home';
  }

  return (
    <div className="Login_page">
      <img src={logo_aguafly} className="logo_login_page" alt="logo" />
      {!authData ? (
        <div>
          <form onSubmit={handleSubmit}>
            <Search>
              <SearchIconWrapper>
                <PersonIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Username"
                inputProps={{ 'aria-label': 'search' }}
                onChange={evt => setUsername(evt.target.value)}
                id="username"
                value={username}
              />
            </Search>
            <Search>
              <SearchIconWrapper>
                <HttpsIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Password"
                inputProps={{ 'aria-label': 'search' }}
                type="password"
                value={password}
                onChange={evt => setPassword(evt.target.value)}
              />
            </Search>
            <ThemeProvider theme={theme}>
              <Button
                style={{ minWidth: '300px', borderRadius: 18, marginLeft: '30px' }}
                variant="contained"
                color="success"
                type="submit"
              >
                LOGIN
              </Button>
            </ThemeProvider>
            <div className="loginPage_Inner">
              <div>
                <ThemeProvider theme={theme}>
                  <h3 className="forgot_pass_register_now">
                    <Typography> Register Now!</Typography>
                  </h3>
                </ThemeProvider>
              </div>
              <div>
                <h3 className="forgot_pass">
                  <Typography style={{ color: 'white' }}>Forgot password?</Typography>
                </h3>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div>
        <Link  to ={`/home/`}>    </Link>
       
         <h3 className="welcomeback">Welcome Back {authData.user.username}</h3>
         <Button  style={{minWidth: '300px', borderRadius: 18}} variant="contained" color="success" onClick={()=> continueToFunc()}>{continueTo}         </Button>
         <br></br>
         <br></br>
         <Button  style={{minWidth: '300px', borderRadius: 18}} variant="contained" color="success" onClick={()=> logOut()}>{logout}         </Button>

       </div>
      )}
    </div>
  );
}

export default Auth;