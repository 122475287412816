import React, { useState } from 'react';
import { Box, CssBaseline, Typography, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Navbar from './navbar';  // Custom Navbar Component
import Sidebar from './sideBar';  // Sidebar Component
import FooterSection from './footer';  // Footer Component
import MainSection from './mainSection';
import MainSectionUp from './mainSectionUp';

// Custom theme creation function
const getTheme = (mode) => createTheme({
  palette: {
    mode,
  },
});

const TestLayout = () => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [darkMode, setDarkMode] = useState(false);

  const theme = getTheme(darkMode ? 'dark' : 'light');

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const handleThemeChange = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      
      {/* Main container with grid layout */}
      <Box sx={{
        display: 'grid',
        gridTemplateRows: '64px 1fr 40px', // Navbar (fixed), Main (dynamic), Footer (fixed)
        height: '100vh', // Full viewport height
        gridTemplateColumns: '1fr',  // Single column layout
      }}>
        {/* Navbar */}
        <Navbar onThemeToggle={handleThemeChange} darkMode={darkMode} />

        {/* Main Content Section with Sidebar */}
        <Box sx={{ display: 'flex', gridRow: '2 / 3', overflow: 'hidden' }}>
          {/* Sidebar */}
          <Sidebar open={sidebarOpen} toggleSidebar={toggleSidebar} />

          {/* Main Content */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 0,
              backgroundColor: theme.palette.background.default,
              overflowY: 'auto',  // Allow scrolling for long content
            }}
          >
            <MainSectionUp />
          </Box>
        </Box>

        {/* Footer */}
        <FooterSection sx={{ gridRow: '3 / 4' }} />
      </Box>
    </ThemeProvider>
  );
};

export default TestLayout;
