import React from 'react';
import { Box, Typography } from '@mui/material';

const FooterSection = () => {
  const currentYear = new Date().getFullYear(); // Get the current year

  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: '#2082a4',  // Fixed background color
        color: 'white',
        textAlign: 'center',
        height: '40px',  // Reduced height for thinner footer
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body2">
        &copy; {currentYear} Aquafly. All rights reserved.
      </Typography>
    </Box>
  );
};

export default FooterSection;
