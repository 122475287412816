
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from './en.json';
import frTranslations from './fr.json';
import itTranslations from './it.json';
//import trTranslations from './tr.json';

const resources = {
  en: { translation: enTranslations },
  fr: { translation: frTranslations },
  it: { translation: itTranslations },
  //tr: { translation: trTranslations},
  // Additional languages...
};

// Detect the user's browser language
const browserLanguage = window.navigator.language.split('-')[0]; // Example: 'en-US' -> 'en'

// Check if the detected browser language is supported
const defaultLanguage = resources[browserLanguage] ? browserLanguage : 'en';

i18n
  .use(initReactI18next) // Pass the i18n instance to react-i18next.
  .init({
    resources,
    lng: defaultLanguage, // Use the detected language or fallback to 'en'
    fallbackLng: 'en', // Set a fallback language in case the current language can't be found
    interpolation: {
      escapeValue: false, // React already does escaping
    },
    // Add other configuration options as needed
  });

export default i18n;
