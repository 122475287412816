// GreyBackgroundElement.js
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './sensingPageMain.css'

import SyncIcon from '@mui/icons-material/Sync';
import Button from '@mui/material/Button';
import DownloadingIcon from '@mui/icons-material/Downloading';
import Stack from '@mui/material/Stack';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import Forward5Icon from '@mui/icons-material/Forward5';
import NotificationsPanel from '../../layoutElements/notification';
import LightModeIcon from '@mui/icons-material/LightMode';
import MapViewMulti from '../../components/leaFlatFieldMapMulti';
import { Link, useParams } from 'react-router-dom'
import { useFetchFieldCoordinatesMulti } from '../../hooks/fetch_FieldCoordinates_Multi';
import { useToken } from '../../hooks/useToken';
import { useFarmData } from '../../hooks/fetch_apiFarm';
import { useFarmDataList } from '../../hooks/fetch_apiFarmList';
import { useNavigate } from 'react-router-dom';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { polygon } from 'leaflet';
import { Box, CircularProgress } from '@mui/material';
import { useDeviceData } from '../../services/useDeviceData';
import MapWS from './mapSensing';


import LineChartHum from './charts/sensingLineChartHum';
import LineChartWind from './charts/sensingLineChartWind';
import LineChartRain from './charts//sensingLineChartRain';
import LineChartUV from './charts/sensingLineChartUV';
import LineChartLight from './charts/sensingLineChartLight';
import LineChartPres from './charts/sensingLineChartPres';
import LineChartAR from './charts/sensingLineChartAR';
import LineChart from './charts/sensingLineCharts';

import AirIcon from '@mui/icons-material/Air';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloudQueueOutlinedIcon from '@mui/icons-material/CloudQueueOutlined';
import DeviceThermostatIcon from '@mui/icons-material/DeviceThermostat';



const style = {
    backgroundColor: 'white', // This sets the background color to grey
    padding: '20px', // Add some padding for content inside
    marginLeft: '1%', // Overrides the left padding to be 5% of the parent's width
    margin: '10px 1%', // Add some vertical margin
    borderRadius: '5px', // Optional: rounds the corners of the element

};


const SensingPageMain = ({ children }) => {


    const shouldShowPumpOverlay = false;
    const token = useToken()
    //const id   = 9// useParams();
    //console.log("id", id)   
    console.log("token", token)
    const { data: dataList, loading: loadingList, error: errorList } = useFarmDataList(token);
    const id = dataList && dataList.length > 0 ? dataList[0].id : null;


    console.log("farm data list", dataList)
    //const { data, loading, error } = useFarmData(token, id); 
    const [selectedFarm, setSelectedFarm] = useState('');
    const [selectedFarmId, setSelectedFarmId] = useState(null);
    useEffect(() => {
        if (dataList && dataList.length > 0) {
            setSelectedFarm(dataList[0].name); // Default to first farm name
            setSelectedFarmId(dataList[0].id);  // Default to first farm ID
        }
    }, [dataList]);
    const { data, loading, error } = useFarmData(token, selectedFarmId);
    console.log("farm data test", data)
    const handleChange = (event) => {
        const farmName = event.target.value;
        setSelectedFarm(farmName);

        // Find the selected farm object and update the selectedFarmId
        const selectedFarmData = dataList.find(farm => farm.name === farmName);
        if (selectedFarmData) {
            setSelectedFarmId(selectedFarmData.id);  // Trigger refetch by changing the ID
        }
    };


    console.log("farm data", data)
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const [polygonCoordinatesUp, setPolygonCoordinatesUp] = useState([]);
    const [content, setContent] = useState('');
    const handlePolygonClick = (polygon) => {
        setContent(polygon);
    };

    const { dataFieldCoordinatesMulti, loadingCoor, errorCoor } = useFetchFieldCoordinatesMulti(token, selectedFarmId);
    console.log("dataFieldCoordinatesMulti", dataFieldCoordinatesMulti)

    useEffect(() => {
        console.log("only coors initial", dataFieldCoordinatesMulti);

        // Check if data is available and process it
        if (dataFieldCoordinatesMulti && dataFieldCoordinatesMulti.length > 0) {
            const filteredData = dataFieldCoordinatesMulti.filter(fieldData =>

                fieldData.farm_info === data[0].name

            );
            //console.log("data name ",data[0].name)
            console.log("Filtered Data:", filteredData);
            const cornersDataOld = filteredData.map(fieldData =>
                fieldData.coordinateInfo?.[0]?.corners ?? [],


            );

            const cornersData = filteredData.map(fieldData => ({
                coordinates: fieldData.coordinateInfo?.[0]?.corners ?? [],
                fieldInfo: fieldData.field_name // Capture the field_info here  
                //it was field_info to capture field id to show on map
            }));


            console.log("only coors corners", cornersData);

            if (cornersData.length > 0) {
                const newPolygonCoordinates = cornersData.map(polygonData => ({
                    coordinates: polygonData.coordinates.map(corner => {
                        const key = Object.keys(corner)[0];
                        return corner[key];
                    }),
                    fieldInfo: polygonData.fieldInfo
                }));
                setPolygonCoordinatesUp(newPolygonCoordinates);
                console.log("polygonCoordinatesUp", newPolygonCoordinates)
            }
        }
    }, [dataFieldCoordinatesMulti]);
    const containerStyle = {
        // width: '70vw',
        width: isMobile ? 'auto' : '70vw',
        height: isMobile ? '30vh' : '60vh'
        //width: 'auto',
        //height: '100vh'
        //height: isMobile ? '30vh' : '60vh',
    };

    const solarDevices = [
        {
            id: 1,
            todaysEnergy: '5 kWh',
            power: '3 kW',
            battery: '80%',
            totalEnergy: '50 kWh',
            timestamp: '12/03/2024'
        },
        {
            id: 2,
            todaysEnergy: '6 kWh',
            power: '3.5 kW',
            battery: '85%',
            totalEnergy: '60 kWh',
            timestamp: '12/03/2024'
        },
        {
            id: 3,
            todaysEnergy: '6 kWh',
            power: '3.5 kW',
            battery: '85%',
            totalEnergy: '60 kWh',
            timestamp: '12/03/2024'
        },
        // Add more devices as needed
    ];

    const { t } = useTranslation();

    const navigate = useNavigate();
    const navigateToSatView = (id) => {
        navigate(`/home/sattview/${id}`);
    };

    const [currentTime, setCurrentTime] = useState(new Date());
    /*
      useEffect(() => {
        // Update the time every second
        const timer = setInterval(() => {
          setCurrentTime(new Date());
        }, 1000); 
    
        // Clean up the timer when the component unmounts
        return () => clearInterval(timer);
      }, []); */

    // Format the date and time in 'DD/MM/YYYY HH:MM:SS' format
    const formatDate = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    };



    const handleSatelliteClick = () => {
        // Find the object where field_name matches the content
        const selectedField = dataFieldCoordinatesMulti.find(item => item.field_name === content);

        if (selectedField) {
            const fieldInfo = selectedField.field_info; // Get field_info

            // Navigate to the appropriate route
            navigate(`/home/sattview/${fieldInfo}`);
        } else {
            console.error('Field not found for content:', content);
        }
    };


    const { deviceData, loadingDevice, errorDevice } = useDeviceData(token);
    console.log("device data", deviceData)



    const filteredData = dataFieldCoordinatesMulti && deviceData ? dataFieldCoordinatesMulti
        .filter(field => field.farm_info === selectedFarm)  // Filter fields by the selected farm
        .map(field => {
            const devicesForField = deviceData.filter(device => device.field_info === field.field_info);

            return {
                farm_info: field.farm_info,
                field_info: field.field_info,
                field_name: field.field_name,
                devices: devicesForField
            };
        }) : [];

    const [buttonColor, setButtonColor] = useState('grey');  // Initial color is grey (inactive)
    const [isActive, setIsActive] = useState(false);  // Tracks if the button is currently active (green) or inactive (grey)
    const [isLoading, setIsLoading] = useState(false);  // Tracks if the button is currently active (green) or inactive (grey)

    const handleStartButtom = () => {
        // Step 1: Set loading state (orange)
        setButtonColor('orange');
        setIsLoading(true);  // Set loading to true to show the spinner

        // Step 2: After 2 seconds, toggle between active (green) and inactive (grey)
        setTimeout(() => {
            setIsLoading(false);  // Stop showing the spinner after 2 seconds
            if (isActive) {
                // If currently active, change to inactive (grey)
                setButtonColor('grey');
            } else {
                // If currently inactive, change to active (green)
                setButtonColor('green');
            }
            // Toggle the active/inactive state
            setIsActive(!isActive);
        }, 2000);  // 2 seconds
    };



    return (
        <div className='grid-container-land'>
            <div className='grid-item-title'>
                <FormControl fullWidth>
                    <InputLabel>Select Device</InputLabel>
                    <Select
                        value={selectedFarm}
                        onChange={handleChange}
                        style={{ textAlign: 'left', fontWeight: 'bold' }}
                        displayEmpty
                        label="Select Your Farm"
                        fullWidth={false} // Optional: allows the Select component to follow the FormControl width
                    >
                        {dataList && dataList.map((farm) => (
                            <MenuItem key={farm.id} value={farm.name} style={{ fontWeight: 'bold', textAlign: 'left' }}>
                                {farm.name}
                            </MenuItem>

                        ))}
                    </Select>
                </FormControl>
            </div>
            <div className='grid-item-main'>
                <div className='grid-item-main-left-sensing'>
                    <div className='homePageMainMapWrap'>
                        <MapWS onPolygonClick={handlePolygonClick} />
                    </div>
                    <div className='sensingGraphs'>  {content === 'soil_moisture_X' ? (
                        <div>
                            <div className='parentContainer'>
                                <div style={{
          backgroundColor: '#A6CE39',
          color: 'white',
          padding: '4px 30px',
          fontFamily: 'Nunito Sans',
          fontSize: '20px',
          textAlign: 'center',
          borderRadius: '8px',  // Rounded rectangle
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.3)',
        }} className='sensingTitleUp'>{t("Field")} xxx/{t("Location")}</div>
                            </div>
                            <div className='chartSoil'>
                                <div className='chartSoilInner'>
                                    <div className='spacingSensing'> {t("Depth")}: 10 Cm</div>
                                    <div className='lineChartCont'><LineChart /></div>
                                    <div className='spacingSensing'>{t("Depth")}: 20 Cm</div>

                                    <div className='lineChartCont'><LineChart /></div>
                                    <div className='spacingSensing'>{t("Depth")}: 30 Cm</div>
                                    <div className='lineChartCont'><LineChart /></div>
                                    <div className='spacingSensing'>{t("Depth")}: 40 Cm</div>
                                    <div className='lineChartCont'><LineChart /></div>
                                    <div className='spacingSensing'>{t("Depth")}: 50 Cm</div>
                                    <div className='lineChartCont'><LineChart /></div>
                                </div>
                                <div className='chartSoilInner'>
                                    <div className='spacingSensing'></div>
                                    <div className='lineChartCont'><LineChartHum /></div>
                                    <div className='spacingSensing'></div>
                                    <div className='lineChartCont'><LineChartHum /></div>
                                    <div className='spacingSensing'></div>
                                    <div className='lineChartCont'><LineChartHum /></div>
                                    <div className='spacingSensing'></div>
                                    <div className='lineChartCont'><LineChartHum /></div>
                                    <div className='spacingSensing'></div>
                                    <div className='lineChartCont'><LineChartHum /></div>
                                </div>
                            </div>
                        </div>) : (
                        <div className='chartWS'>
                            <div className='chartWSinner'>
                                <LineChart></LineChart>
                                <LineChartWind></LineChartWind>
                                <LineChartUV></LineChartUV>
                                <LineChartPres></LineChartPres>
                            </div>
                            <div className='chartWSinner'>
                                <LineChartHum></LineChartHum>
                                <LineChartRain></LineChartRain>
                                <LineChartLight></LineChartLight>
                                <LineChartAR></LineChartAR>
                            </div>

                        </div>)}</div>

                </div>

                <div className='grid-item-main-right'>
                    
                            {content ? (

                                <div className='weatherStationPanel'>
                                    <div style={{ fontSize: '12px', marginTop: '1%', marginLeft: '5%', fontWeight: '600', fontFamily: 'Open Sans', color: 'grey', textAlign:'right', marginRight:'5%', marginTop:'2%' }}>27/03/2024 12:45:50</div>
                                    <div></div>
                                    <div>
                                        {content === 'weather_X' &&
                                            <div className='weatherStationSelected'>
                                                <div style={{ fontSize: '16px', marginTop: '0%', marginLeft: '5%', fontWeight: '700', fontFamily: 'Open Sans', color: '#2082A4', }}>{t("Weather Station")} XXX {t("Data")}</div>
                                                <div></div>
                                                <div className='WSboxLayer'>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4',display: 'flex',justifyContent: 'center', fontSize: '16px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'center' }}><DeviceThermostatIcon fontSize="large" style={{ marginRight: '10px' }} /> 12 °C</div>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', display: 'flex',justifyContent: 'center', fontSize: '16px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'center' }}><AirIcon fontSize="large" style={{ marginRight: '10px' }} />8 km/h</div>
                                                    <div></div>
                                                </div>
                                                <div> </div>
                                                <div className='WSboxLayer'>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4',display: 'flex',justifyContent: 'center', fontSize: '16px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'center' }}><CloudQueueOutlinedIcon fontSize="large" style={{ marginRight: '10px' }} /> 15 mm</div>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4',display: 'flex',justifyContent: 'center', fontSize: '16px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'center' }}><ArrowForwardIcon fontSize="large" style={{ marginRight: '10px' }} />{t("East")}</div>
                                                    <div></div>
                                                </div>

                                            </div>}
                                        {content === 'soil_moisture_X' &&
                                            <div className='soilMoistureSelected'>
                                                <div style={{ fontSize: '16px', marginTop: '0%', marginLeft: '5%', fontWeight: '700', fontFamily: 'Open Sans', color: '#2082A4' }}>{t("Soil Data")}, {t("Sensor")} No: 1</div>
                                                <div></div>
                                                <div className='soilBoxLayer'>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', fontSize: '14px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'center' }}>10 cm</div>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', fontSize: '14px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'center' }}>{t("temperature")}:</div>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', fontSize: '14px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'center' }}>{t("humidity")}:</div>
                                                </div>
                                                <div> </div>
                                                <div className='soilBoxLayer'>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', fontSize: '14px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'center' }}>20 cm</div>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', fontSize: '14px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'center' }}>{t("temperature")}:</div>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', fontSize: '14px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'center' }}>{t("humidity")}:</div>
                                                </div>
                                                <div> </div>
                                                <div className='soilBoxLayer'>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', fontSize: '14px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'center' }}>30 cm</div>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', fontSize: '14px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'center' }}>{t("temperature")}:</div>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', fontSize: '14px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'center' }}>{t("humidity")}:</div>
                                                </div>
                                                <div> </div>
                                                <div className='soilBoxLayer'>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', fontSize: '14px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'center' }}>40 cm</div>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', fontSize: '14px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'center' }}>{t("temperature")}:</div>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', fontSize: '14px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'center' }}>{t("humidity")}:</div>
                                                </div>
                                                <div> </div>
                                                <div className='soilBoxLayer'>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', fontSize: '14px', fontWeight: '700', fontFamily: 'Open Sans', textAlign: 'center' }}>50 cm</div>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', fontSize: '14px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'center' }}>{t("temperature")}:</div>
                                                    <div></div>
                                                    <div className='WSboxInner' style={{ color: '#2082A4', fontSize: '14px', fontWeight: '600', fontFamily: 'Open Sans', textAlign: 'center' }}>{t("humidity")}:</div>
                                                </div>

                                            </div>}
                                        {content === 'A' && <div style={{ fontSize: '18px', marginTop: '0%', marginLeft: '5%', fontWeight: '700', fontFamily: 'Open Sans' }}>Route to field A page</div>}
                                        {content === 'B' && <div style={{ fontSize: '18px', marginTop: '0%', marginLeft: '5%', fontWeight: '700', fontFamily: 'Open Sans' }}>Route to field B page</div>}
                                    </div>

                                    <div> </div>
                                </div>)

                                :
                                (<div></div>)}

                       
                    <div className='' >
                        <NotificationsPanel></NotificationsPanel>

                    </div>

                </div>
            </div>
        </div>

    );
};

{/* 
        
    <div className='mainSectionTitle'>
      <div  style={{marginTop: '1.45%', width: '30%', marginLeft: '2.5%'}}>
       <FormControl fullWidth>
       <InputLabel>Select Your Farm</InputLabel>
      <Select
        value={selectedFarm}
        onChange={handleChange}
        displayEmpty
        label="Select Your Farm"
        fullWidth={false} // Optional: allows the Select component to follow the FormControl width
      >
        {dataList && dataList.map((farm) => (
          <MenuItem key={farm.id} value={farm.name}>
            {farm.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
      </div>

      <div className="grid-container">
        <div></div>
        <div className='grid-containerVerticalMain'>
          <div className='mapWrapMain'>
            <MapViewMulti  combinedData={filteredData} containerStyle={containerStyle} polygonCoordinatesUp={polygonCoordinatesUp}  onClick={() => navigateToSatView(id)} navigateToSatView={navigateToSatView} onPolygonClick={handlePolygonClick} ></MapViewMulti>
          </div>
          <div className='MainDownLeft'>

            <div className='MainDownLeftSolarDev'>
              
              {solarDevices.map((device) => (
                <div className='SolarItemCenter'>
                  <div className='solarDeviceMainTitle'>{t("solar device")} {device.id}</div>
                  <div className='solarDeviceTitle'>
                    <div className='informPanelTitlesDetail'>
                      <div>{t("todays energy")}: {device.todaysEnergy}</div>
                      <div>{t("power")}: {device.power} </div>
                      <div>{t("battery")}: {device.battery}</div>
                      <div>{t("total energy")}: {device.totalEnergy}</div>
                    </div>
                    <LightModeIcon style={{ fontSize: '60px', marginRight: '10px' }} />
                  </div>
                  <div style={{ fontSize: '12px', marginTop: 'auto', marginBottom: 'auto', fontWeight: '600', fontFamily: 'Open Sans' }}>27/03/2024 12:45:50 <SyncIcon fontSize='small' style={{ marginLeft: "20%" }} ></SyncIcon></div>
                </div>
              ))}
              {/*
              <div className='SolarItemCenter'>Solar Device 2</div>
              <div className='SolarItemRight'>Solar Device 3</div>*-/}
            </div>
            
            <div></div>


            <div className='MainDownLeftPumps'>
              <div className='pumpItemBox'>
                <div>
                  {!shouldShowPumpOverlay && <div className='pumpItemInner'>Autostrada</div>}
                  <div className='allBoxText'>
                    {!shouldShowPumpOverlay ? (
                      <>
                        <div>Dir: {t("FWD")}</div>
                        <div>{t("angle")}: 174.5</div>
                        <div>{t("speed")}: 100%</div>
                        <div>{t("rate")}: 0mm</div>
                        <div>{t("pres")}: 0</div>
                        <div>{t("stop at")}: 175</div>
                        <div className='timeStamp'>27/03/2024 10:20:55 <SyncIcon fontSize='small' style={{ marginLeft: "20%" }} /></div>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className='boxLine'></div>
                <div>
                  {!shouldShowPumpOverlay && <div className='pumpItemInner'>Lago</div>}
                  <div className='allBoxText'>
                    {!shouldShowPumpOverlay ? (
                      <>
                        <div>Dir: REV</div>
                        <div>{t("angle")}: 4</div>
                        <div>{t("speed")}: 100%</div>
                        <div>{t("rate")}: 0mm</div>
                        <div>{t("pres")}: 0</div>
                        <div>{t("stop at")}: 120</div>
                        <div className='timeStamp'>27/03/2024 10:20:55 <SyncIcon fontSize='small' style={{ marginLeft: "20%" }} /></div>
                      </>
                    ) : null}
                  </div>
                </div>
                {shouldShowPumpOverlay && (
                  <div className='overlay'>
                    <div className='overlayText'>
                      +ADD<br />Device
                    </div>
                  </div>
                )}
              </div>

              
              <div></div>
              <div className='pumpItemBox'>
                <div>
                  {!shouldShowPumpOverlay && <div className='pumpItemInner'>{t("pump")} A</div>}
                  <div className='allBoxText'>
                    {!shouldShowPumpOverlay ? (
                      <>
                        <div style={{ color: 'red' }}>{t("status")}: Off</div>
                        <div>{t("power energia")}: 0.5 kW</div>
                        <div>{t("current")}: 0.2 A</div>
                        <div>{t("hours")}: 0 H</div>
                        <div>{t("pres")}: 0.111 bar</div>
                        <div className='timeStamp' style={{ marginTop: '40px' }}>27/03/2024 11:40:15 <SyncIcon fontSize='small' style={{ marginLeft: "20%" }} /></div>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className='boxLine'></div>
                <div>
                  {!shouldShowPumpOverlay && <div className='pumpItemInner'>{t("pump")} B</div>}
                  <div className='allBoxText'>
                    {!shouldShowPumpOverlay ? (
                      <>
                        <div style={{ color: 'green' }}>{t("status")}: On</div>
                        <div>{t("power energia")}: 0.5 kW</div>
                        <div>{t("current")}: 0.2 A</div>
                        <div>{t("hours")}: 0 H</div>
                        <div>{t("pres")}: 0.111 bar</div>
                        <div className='timeStamp' style={{ marginTop: '40px' }}>27/03/2024 11:40:15 <SyncIcon fontSize='small' style={{ marginLeft: "20%" }} /></div>
                      </>
                    ) : null}
                  </div>
                </div>
                {shouldShowPumpOverlay && (
                  <div className='overlay'>
                    <div className='overlayText'>
                      +ADD<br />Device
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <div></div>
        <div className='grid-containerVerticalMain'>
          {content ? (
            <div className='pivotStatusSectionMain'>
              

              <div style={{ fontSize: '12px', marginTop: '1%', marginLeft: '5%', fontWeight: '600', fontFamily: 'Open Sans', }}>{formatDate(currentTime)}

              </div>
              <div></div>
              <div className='statusStartStopName'>
                <div></div>
                <div style={{ fontSize: '16px', marginTop: '0%', marginLeft: '5%', fontWeight: '700', fontFamily: 'Open Sans',marginRight: '20%' }}>{content} {t("status")}</div>
                
                <div>{polygonCoordinatesUp.fieldInfo}</div>
                <div></div>
                <Button 
                    variant="contained" 
                    style={{ backgroundColor: 'grey', color: 'white',fontSize: '14px',  marginBottom: '20px',marginTop: '0px' ,marginRight: '4%'}}  // Adjust padding and font size
                    size="small"  // Default small size provided by Material-UI
                    endIcon={<SendIcon />}
                    onClick={handleSatelliteClick}
                  >
                    Satellite
                </Button>
                <div></div>
                <Button 
                    variant="contained" 
                    style={{ backgroundColor: 'grey', color: 'white',fontSize: '14px',  marginBottom: '20px',marginTop: '0px', marginRight: '4%' }}  // Adjust padding and font size
                    size="small"  // Default small size provided by Material-UI
                    endIcon={<SendIcon />}
                  >
                    Sensing
                </Button>
              </div>
              <div></div>
              <div className='statusStartStop'>
                <div></div>
                <Stack direction="row" spacing={2}>
                  <Button variant="contained" color="grey" style={{ backgroundColor: 'grey', color: 'white' }} startIcon={<PlayArrowIcon />}>{t("start")}
                  </Button>

                </Stack>
                <div></div>
                <Stack direction="row" spacing={2}>
                  <Button variant="contained" color="error" startIcon={<StopIcon />}>{t("stop")}
                  </Button>

                </Stack>
                <div></div>
                <Stack direction="row" spacing={2}>
                  <Button variant="contained" color="grey" style={{ backgroundColor: 'grey', color: 'white' }} startIcon={<SettingsBackupRestoreIcon />}>{t("reverse")}
                  </Button>

                </Stack>
                <div></div>
                <Stack direction="row" spacing={2}>
                  <Button variant="contained" color="grey" style={{ backgroundColor: 'grey', color: 'white' }} startIcon={<Forward5Icon />}>{t("forward")}
                  </Button>

                </Stack>
              </div>
              <div></div>
              <div className='pivotStatusDetailTable'>
                <div></div>
                <div className='pivotStatusDetailLeft'>
                  <div style={{ backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '14px' }} > {t("end pressure")}:</div>
                  <div style={{ color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '14px' }} > {t("actual speed")}:</div>
                  <div style={{ backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '14px' }}> {t("angle")}:</div>
                  <div style={{ color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '14px' }} > {t("pressure control")}:</div>
                  <div style={{ backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '14px' }}> {t("end gun")}:</div>
                  <div style={{ color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '14px' }} > {t("next stop")}: </div>
                  <div style={{ backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '14px' }}> {t("speed set point")}:</div>
                </div>
                <div></div>
                <div className='pivotStatusDetailLeft'>
                  <div style={{ backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '14px' }} > {t("wire cut alarm")}:</div>
                  <div style={{ color: 'black', paddingLeft: '5%' }} > {t("power supply")}:</div>
                  <div style={{ backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '14px' }}> {t("GPS status")}:</div>
                  <div style={{ color: 'black', paddingLeft: '5%' }} > {t("cellular signal level")}:</div>
                  <div style={{ backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '14px' }}> {t("running hours")}:</div>
                  <div style={{ color: 'black', paddingLeft: '5%' }} > {t("irrigation hours")}: </div>
                  <div style={{ backgroundColor: 'rgba(128, 128, 128, 0.2)', color: 'black', paddingLeft: '5%', fontFamily: 'Open Sans', fontSize: '14px' }}> {t("flowmeter")}:</div>
                </div>
                <div></div>
              </div>
            </div>
          ) : (
            <div></div>)}
          <div className='notificationPart'>
            <NotificationsPanel></NotificationsPanel>
            <div></div>
          </div>

        </div>
        <div></div>
      </div>
    </div> */}

export default SensingPageMain;
